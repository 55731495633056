@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';

.videoPlayButton {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border: 4px solid;
  border-radius: 50%;
  background-color: transparent;
  color: colors.$white;
  cursor: pointer;
  overflow: hidden;

  filter: drop-shadow(0px 4px 20px rgba(35, 47, 57, 0.35));

  &:not(:disabled) {
    &:hover {
      color: colors.$purple500;
      border-color: colors.$white;
      background-color: colors.$white;
    }

    &:active {
      color: colors.$purple600;
      border-color: colors.$white;
      background-color: colors.$white;
    }
  }

  &:disabled {
    &:not(.looksOn) {
      opacity: 0.5;
    }
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px colors.$purple500;
  }
}

.playIcon {
  transform: translateX(2px);
}