@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/elevation';
@use '$styles/variables/spacings';
@use '$styles/variables/radii';
@use '$styles/mediaqueries';

.component {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  appearance: none;
  border: none;
  z-index: 1;
  /* DEV only */
  background: transparent;
  font-size: 0;
  cursor: pointer;
}

.iframeContainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  @include mediaqueries.smallUp {
    border-radius: radii.$XS;
  }
  @include mediaqueries.largeUp {
    border-radius: radii.$S;
  }
}

.videoIFrame {
  height: inherit;
  border: none;
  position: relative;
}
