@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';

.Tag {
  box-sizing: border-box;
  font-family: Jokker, sans-serif;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  background-color: transparent;
  white-space: nowrap;

  /* Small (default) */
  font-size: 14px;
  padding: 1px 6px;
  color: colors.$neutral400;
  border-radius: 4px;
  line-height: 18px;
  border: 2px solid;
  @media (-webkit-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    line-height: 19px;
    border-width: 1.5px;
  }

  /* Medium */
  &.medium {
    line-height: inherit;
    @media (-webkit-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      line-height: 25px;
    }
    height: 50px;
    padding: 4px 8px;
    color: colors.$neutral600;
  }
}
