@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use '$styles/mediaqueries';

.component {
  :global {
    .slick-track {
      display: flex !important;
      margin-bottom: 20px !important;
    }
  
    .slick-slide {
      height: inherit !important;
    }
    .slick-slide > div {
      height: 100% !important;
    }
  
    .slick-list {
      cursor: grab;
  
      @include mediaqueries.extraLargeUp {
        &::before,
        &::after {
          top: 0;
          content: ' ';
          display: block;
          position: absolute;
          width: 44px;
          bottom: 0;
          z-index: 100;
  
          @include mediaqueries.smallUp {
            width: 50px;
          }
  
          @include mediaqueries.mediumUp {
            width: 56px;
          }
        }
  
        &::before {
          left: 0;
          background-image: linear-gradient(
            to right,
            white,
            transparent
          );
        }
  
        &::after {
          right: 0;
          background-image: linear-gradient(
            to left,
            white,
            transparent
          );
        }
      }
    }
  
    .slick-slider {
      padding-bottom: 76px;
      @include mediaqueries.smallUp {
        padding-bottom: 108px;
      }
    }
  
    .slick-slide {
      padding: 0 8px;
      box-sizing: border-box;
    }
  
    .slick-arrow {
      right: 30px;
      bottom: 0;
      position: absolute;
      appearance: none;
      border: none;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 52px;
      cursor: pointer;
      background-color: colors.$purple100;
  
      &.prev {
        right: calc(30px + 52px + 8px);
      }
  
      .slick-arrow-prev {
        transform: rotate(180deg);
      }
  
      @include mediaqueries.smallUp {
        right: 72px;
        bottom: 12px;
  
        &.prev {
          right: calc(72px + 52px + 8px);
        }
      }
    }
  }
}