@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/fonts';
@use '$styles/variables/colors';
@use '$styles/mediaqueries';

.component {
  position: relative;

  img {
    border-radius: 6px;
    width: 100%;
    height: 100%;
  }

  :global(.VideoPlayButton) {
    margin: auto;
  }

}

.video {
  cursor: pointer;
  &:hover {
    :global(.VideoPlayButton) {
      color: colors.$purple500;
      border-color: colors.$white;
      background-color: colors.$white;
    }
  }

  :global(.Tag) {
    position: inherit;
    color: colors.$neutral100;
  }
}

.videoInfo {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}   

.videoPlayButton {
  flex: 1;
  display: flex;
}

.description {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
  width: 100%;
  padding: 10px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  position: relative;
  overflow: hidden;
  height: 64px;
  @include mediaqueries.mediumUp {
    padding: 13px;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparentize($color: colors.$brandDarkPurple, $amount: 0.3);
    mix-blend-mode: multiply;
  }
}

.title {
  font: fonts.$labelL;
  font-weight: 600;
  position: inherit;
  display: inline;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: colors.$neutral100;
  margin: 0;
}